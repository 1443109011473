import React from 'react';
import ReactDOM from 'react-dom/client';
import { MetaMaskProvider } from "metamask-react";
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MetaMaskProvider>
      <App />
    </MetaMaskProvider>,
);
