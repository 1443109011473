import React, { Suspense, lazy } from 'react';
import LoadingScreen2 from './components/loading2'

const MintSection = lazy(() => import ('./components/mint'));

function App() {
  return (
    <Suspense fallback={<LoadingScreen2/>}>
      <MintSection />
    </Suspense>
  );
}

export default App;
